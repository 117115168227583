/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ContactForm, Text, Button, Subtitle, Image } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-ak3sq9 --style3 --full pb--10" name={"introduction"} parallax={false} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--2 pl--20 pr--20 flex--center" style={{"maxWidth":""}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--08 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"5"} style={{"maxWidth":524,"backgroundColor":"var(--color-dominant)"}}>
              
              <Title className="title-box fs--36 ls--004 lh--1" style={{"maxWidth":966}} content={"Where are we going?"}>
              </Title>

              <ContactForm className="--shape2 --style1 fs--16 lh--16 mt--20" style={{"maxWidth":526}} action={"/contact"} fields={[{"name":"Starting point","type":"text","required":true,"placeholder":"Starting point"},{"name":"Destination","type":"text","placeholder":"Destination"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Contact e-mail address:"},{"name":"Check availibility","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--86 w--500 ls--004 lh--1" style={{"maxWidth":966}} content={"<span style=\"color: rgba(255,255,255,1);\">A reliable logistics partner.</span><br>"}>
              </Title>

              <Text className="text-box fs--20 w--500 ls--002 lh--16" style={{"maxWidth":509}} content={"<span style=\"color: rgba(255,255,255,1);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--60" name={"introduction-2"}>
          
          <ColumnWrap className="column__flex --left el--4 pl--0 pr--0 flex--center" style={{"maxWidth":1280}} columns={"4"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":311}}>
              
              <Title className="title-box fs--24" style={{"maxWidth":254}} content={"Intrastate transport"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

              <Button className="btn-box btn-box--pbtn3" content={"More ..."}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":311}}>
              
              <Title className="title-box fs--24" style={{"maxWidth":269}} content={"Interstate transport"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

              <Button className="btn-box btn-box--pbtn3" content={"More ..."}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":311}}>
              
              <Title className="title-box fs--24" content={"Cargo transport"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

              <Button className="btn-box btn-box--pbtn3" content={"More ..."}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":311}}>
              
              <Title className="title-box fs--24" content={"Goods delivery"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

              <Button className="btn-box btn-box--pbtn3" content={"More ..."}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"services"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --left el--2 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 mb--10 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":526}}>
              
              <Subtitle className="subtitle-box" content={"30+ years"}>
              </Subtitle>

              <Title className="title-box fs--72 mt--10" content={"<span style=\"color: var(--color-dominant);\">Years of proven experience</span>"}>
              </Title>

              <Text className="text-box fs--18" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/159/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/159/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/159/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/159/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/159/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/159/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"services-2"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex --left el--2 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 mb--10" anim={"5"} animS={"5"} style={{"maxWidth":526}}>
              
              <Title className="title-box fs--72 mt--10" content={"<span style=\"color: rgba(255,255,255,1);\">Hassle-free delivery guaranteed<br></span>"}>
              </Title>

              <Text className="text-box fs--18" content={"<span style=\"color: rgba(255,255,255,1);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/159/img-3_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/159/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/159/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/159/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/159/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/159/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1v78y5t pb--60 pt--60" name={"contact"} parallax={false} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/159/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--48 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Where are we going?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" content={"Order"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Land Transport</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">About us</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Contact</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">510-851-40X4<br>722 Finch Street</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">ID: 12345678<br>Created with <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}